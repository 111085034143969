
import { Component, Vue } from "vue-property-decorator";
import moment from 'moment';
import db from "@/db";
import store from "../store";

class RegisterForm {
  email = "";
  contactPhone = "";
  firstName = "";
  middleName = "";
  lastName = "";
  companyName = "";
  invoiceStreet = ""
  invoiceHouseNumber = ""
  invoiceAddition = ""
  invoiceZipCode = ""
  invoiceCity = ""
  invoiceCountry = ""
  invoiceTAV = ""
  invoiceEmail = ""
  invoiceBankAccount = ""
  invoiceTNV = ""
  invoiceKVK = ""
  invoiceBTW = ""
  licenseAmount = 0;
}

@Component
export default class Home extends Vue {
  step = 1
  registrationForm = false
  licenseForm = false
  invoiceDataForm = false
  overviewDataForm = false
  registerForm = new RegisterForm()
  newsLetter = true
  startDate = moment().locale('nl').format('DD-MMMM-YYYY')
  reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
  showSignUpStepper = true
  numberRegex = /^[1-9]\d*$/
  termsAndConditions = false
  licensePrice = 0
  BTWAmount: any = 0
  totalPrice = null
    
    
    // This is the function that calculates the price at step 4 and 5
    calculatePrice() {
      const pricePerLicense = this.getPricePerLicense(this.registerForm.licenseAmount)
      this.licensePrice = Number(this.registerForm.licenseAmount * pricePerLicense);
      this.BTWAmount = this.licensePrice * 0.21;
      this.BTWAmount = this.BTWAmount.toFixed(2);
      this.totalPrice = Number(this.licensePrice) + Number(this.BTWAmount);
      this.totalPrice = this.totalPrice.toFixed(2);
    }

    getPricePerLicense(licenseAmount) {
        if(licenseAmount > 9 && licenseAmount < 26) return 45;
        if(licenseAmount > 25 && licenseAmount < 51) return 42.5;
        if(licenseAmount > 50 && licenseAmount < 76) return 40;
        if(licenseAmount > 75) return 37.5
        return 45
    }

    // This is the function that checks the register step.
    validateRegister() {
        if (this.registerForm.firstName == "" || this.registerForm.lastName == "" || this.registerForm.contactPhone == "" || this.registerForm.companyName == "") {
          this.$q.notify({
            // checks if all the fields with a * are filled else you get a negative error
            color: "negative",
            message: "Vul alstublieft alle velden met een * in.",
          });
        } else if (!this.reg.test(this.registerForm.email) ? true : false) {
          this.$q.notify({
            // checks if the email is valid else you get a negative error
            color: "negative",
            message: "Vul een geldig email in.",
          });
        } else {
          this.registrationForm = true;
          this.step = 2;
        }
    }


    // This is the function that checks the purchase step.
    validatePurchase() {
        if (this.registerForm.licenseAmount <= 5) {
          // checks if the data the input is higher then 0 else you get a negative error
          this.$q.notify({
            color: "negative",
            message: "Minimaal aantal licenties is 6",
          });
        } else if (!this.numberRegex.test(this.registerForm.licenseAmount.toString()) ? true : false) {
          // checks if the amount of licenses is integer and not float
          this.$q.notify({
            color: "negative",
            message: "Vul alstublieft een rond aantal in",
          });
        } else {
          // if all of the above are good then this gets done
          this.licenseForm = true;
          this.step = 3;
        }
    }


    // // This is the function that checks the invoiceData step.
    validateInvoiceData() {
        if (this.registerForm.invoiceEmail == '' || this.registerForm.invoiceStreet == '' || this.registerForm.invoiceCity == '' || this.registerForm.invoiceZipCode == '' || 
            this.registerForm.invoiceCountry == '' || this.registerForm.invoiceTNV == '' || this.registerForm.invoiceKVK == '' || 
            this.registerForm.invoiceBTW == '' || this.registerForm.invoiceBankAccount == '') {
          // checks if the input has data else you get a negative error
          this.$q.notify({
            color: "negative",
            message: "Vul alstublieft alle velden met een * in.",
          });
        } else if (!this.numberRegex.test(this.registerForm.invoiceHouseNumber.toString()) ? true : false) {
          // checks if housenumber is round and not for example 2.5
          this.$q.notify({
            color: "negative",
            message: "Vul alstublieft een rond aantal in",
          });
        } else if (!this.reg.test(this.registerForm.invoiceEmail) ? true : false) {
          this.$q.notify({
          // checks if the email is valid else you get a negative error
            color: "negative",
            message: "Vul een geldig email in.",
          });
        } else {
          // if all of the above are good then this gets done
          this.invoiceDataForm = true;
          this.step = 4;
        }
    }


    // This is the function that checks the purchaseoverview step.
  async validatePurchaseOverview() {
    // this is the jsonData so we can send this with the email
    const jsonData = {
        contactEmail: this.registerForm.email,
        contactPhone: this.registerForm.contactPhone,        
        firstName: this.registerForm.firstName,
        middleName: this.registerForm.middleName,
        lastName: this.registerForm.lastName,
        companyName: this.registerForm.companyName,
        licenseAmount: this.registerForm.licenseAmount,
        BTWAmount: this.BTWAmount,
        totalPrice: this.totalPrice,
        invoiceStreet: this.registerForm.invoiceStreet,
        invoiceHouseNumber: this.registerForm.invoiceHouseNumber,
        invoiceAddition: this.registerForm.invoiceAddition,
        invoiceZipCode: this.registerForm.invoiceZipCode,
        invoiceCity: this.registerForm.invoiceCity,
        invoiceCountry: this.registerForm.invoiceCountry,
        invoiceTAV: this.registerForm.invoiceTAV,
        invoiceEmail: this.registerForm.invoiceEmail,
        invoiceBankAccount: this.registerForm.invoiceBankAccount,
        ascription: this.registerForm.invoiceTNV,
        COC_Number: this.registerForm.invoiceKVK,
        VAT_Number: this.registerForm.invoiceBTW,
        acceptedTermsAndConditions: this.termsAndConditions,
        newsLetter: this.newsLetter,
        startDate: this.startDate,
      }
      // This function checks if there is no error and then sends a email.
        if (this.termsAndConditions == false) {
          // checks if the terms and conditions are accepted
          this.$q.notify({
            color: "negative",
            message: "De voorwaarden zijn niet geaccepteerd",
          });
        } else {
          // this sends the email when the user filled in everything correctly
          this.showSignUpStepper = false
          const data = {
            user: {
              email: this.registerForm.email,
              password: "Test@123",
            },
            organization: jsonData
          }
          await store.dispatch('api/signup', data).then((res) => {
            console.log(res);
          }).catch((err) => {
            console.log(err)
          })
          // const sendMail = functions.httpsCallable('sendOrderConfirmationMail');
          // sendMail(jsonData)
          // .then()
          // .catch(error => {
          //   console.log('error: ', error);
          // });
        }
  }
    
  async saveData(dataToSave) {
    const tenantID = this.createTenantID(dataToSave.companyName)
    await db.collection(`tenants/${tenantID}/settings`).doc('organization').set(dataToSave, {merge: true})
  }

  createTenantID(companyName) {
    const companyABBR = companyName.slice(0, 3)
    const randomID = db.collection('tenants').doc().id
    return companyABBR.toUpperCase() + randomID
  }
}
